import React from "react";
import styled from "@emotion/styled";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Heading1 from "../ui/Heading1";
import ParagraphExLarge from "../ui/ParagraphExLarge";

const Content = styled.article`
  max-width: 1242px;
  margin: auto;
  padding: 40px 20px;

  section {
    margin-bottom: 60px;
  }
`;

const Revised = styled.em`
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 60px;
  display: block;
`;

const PrivacyPage = () => {
  return (
    <Layout>
      <SEO title="Accessibility" />
      <Content>
        <Heading1>Accessibility</Heading1>
        <Revised>Last Revised on December 18, 2019</Revised>

        <section>
          <ParagraphExLarge>
            Small Door is committed to providing a website and mobile app that
            are accessible and navigable to all consumers. We are actively
            working to increase the accessibility and usability of our website
            and mobile app, using available standards and guidelines to assist
            in our efforts. We seek to comply with all applicable state and
            federal accessibility requirements as well as best practices in
            providing accessibility.
          </ParagraphExLarge>

          <ParagraphExLarge>
            We use the{" "}
            <a href="https://www.essentialaccessibility.com/blog/web-content-accessibility-guidelines/">
              Web Content Accessibility Guidelines
            </a>{" "}
            as a model, for which our website and mobile app conform to level
            Double-A. These guidelines explain how to make web content more
            accessible for people with disabilities. We regularly review our
            site in comparison to these guidelines using recognized
            accessibility evaluation tools. Essentially, conformance with these
            guidelines will help make the web more user-friendly for all of our
            consumers.
          </ParagraphExLarge>

          <ParagraphExLarge>
            However, while we strive to adhere to the accepted guidelines and
            standards for accessibility and usability, it is not always possible
            to do so in all areas of the website and app at all times. If you
            encounter any difficulty using our website or mobile app, have
            suggestions about improving the usability or accessibility of our
            website or app, please contact us at:
          </ParagraphExLarge>

          <ParagraphExLarge>
            <strong>Email:</strong>{" "}
            <a href="mailto:hi@smalldoorvet.com">hi@smalldoorvet.com</a>
          </ParagraphExLarge>

          <ParagraphExLarge>
            Mailing address:
            <br />
            333 Park Avenue South
            <br />
            Suite 5C
            <br />
            New York, NY 10010
            <br />
          </ParagraphExLarge>
        </section>
      </Content>
    </Layout>
  );
};

export default PrivacyPage;
